<template>

	<div id="historia" v-if="dataReady">

		<div class="container">
			<div class="col-12 text-center mb140">
				<img src="@/images/textos/a-nossa-historia.svg" style="max-width: 100%;" alt="A nossa história">
			</div>
		</div>

		<div class="row no-gutters mb100">
			<div class="col-12 col-lg-7 align-self-center">
				<img src="@/images/historia-fritadeira.jpg" style="max-width: 100%;">
			</div>
			<div class="col-12 col-lg-6 minus-one-col-lg align-self-center historia-text historia-text-bg">
				<h3>{{ pageinfo.title1 }}</h3>
				<p>{{ pageinfo.text1 }}</p>
			</div>
		</div>

		<div class="container">

			<div class="row">
				<div class="col-12 col-lg-6 order-lg-2 mb70 d-flex align-items-center justify-content-center">
					<img src="@/images/historia-ensacar.jpg" class="moldura4" style="max-width:90%;" alt="Cerro da Àguia">
				</div>
				<div class="col-12 col-lg-6 order-lg-1 mb70 align-self-center historia-text">
					<h3>{{ pageinfo.title2 }}</h3>
					<p>{{ pageinfo.text2 }}</p>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-lg-6 mb70 d-flex align-items-center justify-content-center">
					<img src="@/images/logo2.svg" style="max-width: 80%;" alt="Cerro da Àguia">
				</div>
				<div class="col-12 col-lg-6 mb70 align-self-center historia-text">
					<h3>{{ pageinfo.title3 }}</h3>
					<p>{{ pageinfo.text3 }}</p>
				</div>
			</div>

		</div>

		<div class="row no-gutters mb100 mb-lg-200">
			<div class="col-12 col-lg-7 order-lg-2 minus-one-col-lg align-self-center" style="z-index:4">
				<img src="@/images/historia-alentejo.png" style="max-width: 100%;">
			</div>
			<div class="col-12 col-lg-6 order-lg-1 align-self-center historia-text historia-text-bg" style="z-index:5">
				<h3>{{ pageinfo.title4 }}</h3>
				<p>{{ pageinfo.text4 }}</p>
			</div>
		</div>

		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6 mb70 d-flex align-items-center justify-content-center">
					<img src="@/images/historia-batatas-2.png" class="historia-photo-left" alt="Batatas Fritas">
				</div>
				<div class="col-12 col-lg-6 mb70 align-self-center historia-text">
					<h3>{{ pageinfo.title5 }}</h3>
					<p>{{ pageinfo.text5 }}</p>
				</div>
			</div>
		</div>

		<MainFooter />

	</div>

</template>

<script>
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";

export default {
	name: "História",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageinfo: null
		};
	},
	beforeCreate: function() {
        document.body.className = 'pag-historia';
    },
	mounted () {
		axios
			.get(globals.COCKPIT_SERVER+'singletons/get/historia'+globals.COCKPIT_TOKEN)
			.then(response => {
				this.pageinfo = response.data
				this.dataReady = true
				this.changeMetaData()
			})
		;
	},
	metaInfo() {
		return {
			title: 'História'
		}
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = this.$metaInfo.title;
			this.$parent.metaDescription = 'Conheça a nossa história e perceba porque as nossas batatas fritas são excepcionais!';
		}
	}
};
</script>
